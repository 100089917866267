import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Section } from 'app/components/Common/Section'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useState } from 'react'

import { Props as SlideProps, Slide } from './Slide'

export interface Props {
  languageCode: string
  slides: SlideProps[]
}

export const RoomsSlider = memo(function RoomsSlider({
  languageCode,
  slides,
}: Props) {
  if (slides.length < 1) {
    return null
  }

  const [currentSlide, setCurrentSlide] = useState(0)

  const [sliderRef, instanceRef] = useKeenSlider({
    loop: slides.length > 4 ? true : false,
    slides: {
      perView: 4,
      spacing: 20,
    },
    animationEnded(s) {
      setCurrentSlide(s.track.details.rel)
    },
    breakpoints: {
      '(max-width: 1023px)': {
        slides: {
          perView: 3,
          spacing: 16,
        },
      },
      '(max-width: 767px)': {
        slides: {
          perView: 1,
          spacing: 12,
        },
      },
    },
  })

  return (
    <Container>
      <Section label="rooms" languageCode={languageCode} />
      <Wrapper>
        <Title>{useVocabularyData('look-other-rooms', languageCode)}</Title>
        <Slider ref={sliderRef}>
          {slides.map((item, index) => (
            <Slide key={index} className="keen-slider__slide" {...item} />
          ))}
        </Slider>
        {slides.length > 4 ? (
          <Dots dial={2} row>
            {slides.map((item, index) => (
              <Dot
                key={index}
                className={currentSlide === index ? 'active' : undefined}
                onClick={() => {
                  instanceRef.current?.moveToIdx(index)
                }}
              />
            ))}
          </Dots>
        ) : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 13.4375rem;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 11.25rem;
  }

  @media (max-width: 767px) {
    margin-top: 10rem;
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 3.75rem 9.028vw;

  @media (max-width: 1023px) {
    padding: 3rem 1.875rem;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
`

const Slider = styled.div`
  display: flex;
  margin-top: 2.75rem;
  &[data-keen-slider-moves] {
    a {
      pointer-events: none;
    }
  }

  @media (max-width: 1023px) {
    margin-top: 2.25rem;
  }
`

const Dots = styled(FlexBox)`
  margin: 3rem auto 0;
`

const Dot = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.primaryDark1};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  margin: 0 0.625rem;
  &:first-of-type {
    margin-top: 0;
  }
  &.active {
    background: ${({ theme }) => theme.colors.variants.primaryDark1};
    opacity: 1;
    pointer-events: none;
  }
`
