import styled from '@emotion/styled'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

export interface Props {
  label?: string
  languageCode: string
}

export const Section = memo(function Section({
  label = 'explore',
  languageCode,
}: Props) {
  return <Container>{useVocabularyData(label, languageCode)}</Container>
})

const Container = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 11.25rem;
  line-height: 15.25rem;
  overflow: hidden;
  position: absolute;
  top: -9.0625rem;
  right: 0;
  left: 0;
  text-transform: lowercase;

  @media (max-width: 1023px) {
    font-size: 7.5rem;
    line-height: 10.25rem;
    top: -6.125rem;
  }

  @media (max-width: 1023px) {
    font-size: 5rem;
    letter-spacing: -0.125rem;
    line-height: 7.125rem;
    top: -4.125rem;
  }
`
