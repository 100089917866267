import styled from '@emotion/styled'
import { Expand, User } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  pax?: string
  size?: string
  title?: string
}

export const Head = memo(function Head({ pax, size, title }: Props) {
  if (!title || !pax || !size) {
    return null
  }

  return (
    <Container dial={5} row stretch wrap>
      {title ? <Title>{title}</Title> : null}
      {pax || size ? (
        <Info dial={5} row wrap>
          {size ? (
            <Size dial={4} row>
              <Expand />
              {size}
            </Size>
          ) : null}
          {pax ? (
            <Pax dial={4} row>
              <User />
              {pax}
            </Pax>
          ) : null}
        </Info>
      ) : null}
    </Container>
  )
})

const Container = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.25rem;
  line-height: 1.6875rem;
  text-align: center;

  > div {
    flex: 1;
    padding: 3.25rem 6.667vw 3.1875rem;
  }

  @media (max-width: 1023px) {
    > div {
      flex: auto;
      padding: 1.875rem;
    }
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};

  @media (max-width: 1023px) {
    width: 100%;
    order: 2;
  }
`

const Info = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  color: ${({ theme }) => theme.colors.variants.neutralLight4};

  > div {
    &:nth-of-type(2) {
      margin-left: 1.875rem;
    }
  }
  svg {
    width: 1.25rem;
    height: auto;
    max-height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight4};
    margin-right: 1rem;
    transform: translateY(-0.125rem);
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`

const Size = styled(FlexBox)``

const Pax = styled(FlexBox)``
