import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import KeyboardControls from 'app/utils/SliderKeyboardControls'
import { rgba } from 'emotion-rgba'
import { useKeenSlider } from 'keen-slider/react'
import { uniq } from 'lodash'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  images: ImageProps[]
}

export const Gallery = memo(function Gallery({ images }: Props) {
  if (!images) {
    return null
  }

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = React.useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (instanceRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, instanceRef] = useKeenSlider(
    {
      defaultAnimation: {
        duration: 2000,
      },
      loop: true,
      animationEnded(s) {
        setCurrentSlide(s.track.details.rel)
      },
      detailsChanged(s) {
        images.length > 1 ? setDetails(s.track.details) : null
      },
    },
    [KeyboardControls],
  )

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true

    setLoaded(newLoaded)
  }, [currentSlide, instanceRef, sliderRef])

  return (
    <Container>
      <Slider ref={sliderRef}>
        {uniq(images).map((item, index) => (
          <Slide key={index} className="keen-slider__slide">
            <Inner style={positionStyle(index)}>
              {loaded[index] ? (
                <Image media="(min-width: 2800px)" {...item} />
              ) : (
                <Spinner />
              )}
            </Inner>
          </Slide>
        ))}
      </Slider>
      {images.length > 1 ? (
        <Dots row>
          {images.map((item, index) => (
            <Dot
              key={index}
              className={currentSlide === index ? 'active' : undefined}
              onClick={() => {
                instanceRef.current?.moveToIdx(index)
              }}
            />
          ))}
        </Dots>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  padding: 0 6.667vw;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 20%;
    background: ${({ theme }) => theme.colors.variants.neutralLight3};
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    padding: 0;
    &:before {
      display: none;
    }
  }
`

const Slider = styled.div`
  display: flex;
  height: 82vh;
  max-height: 43.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  outline: none;
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0)},
      ${rgba(theme.colors.variants.neutralDark1, 0.5)}
    );
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }

  @media (max-width: 1199px) {
    height: 70vh;
  }

  @media (max-width: 1023px) {
    height: 50vh;
  }
`

const Slide = styled.div`
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Spinner = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  background: none;
  border: 0.25rem solid ${({ theme }) => theme.colors.variants.neutralLight2};
  border-top-color: transparent;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1.25rem 0 0 -1.25rem;
  animation: spin 1.5s infinite linear;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const Dots = styled(FlexBox)`
  position: absolute;
  bottom: 3.75rem;
  left: 50%;
  z-index: 2;
  transform: translateX(-50%);
`

const Dot = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.3;
  margin: 0 0.625rem;
  &.active {
    opacity: 1;
    pointer-events: none;
  }
`
