import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  return (
    <Container>
      {icon ? (
        <Icon dial={5}>
          <SVG src={icon} alt={label} width="30" height="30" />
        </Icon>
      ) : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled.div`
  max-width: 7.5rem;
  margin: 3.125rem 2.5rem 0 0;
  text-align: center;

  @media (max-width: 1023px) {
    margin-right: 1.25rem;
    margin-left: 1.25rem;
  }
`

const Icon = styled(FlexBox)`
  width: 3.75rem;
  height: 3.75rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  border-radius: 50%;
  margin: 0 auto 0.75rem;
`

const SVG = styled.img`
  width: auto;
  max-width: 1.875rem;
  height: 1.25rem;
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
`
